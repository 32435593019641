import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
// import Logo from "../../../assets/img/real-estate-logo-design@2x.png";
import { useTranslation } from 'react-i18next'

import { ReactComponent as CloseMenuIcon } from '../../../assets/icons/exit-menu.svg'
// import logopng from '../../../assets/icons/logo.png'
import OptionsNavbar from './OptionsNavbar'

// import new Navbar Icons
import { ReactComponent as NewLogo } from '../../../assets/icons/Logo_new.svg'
import { ReactComponent as NewNavLinkActive } from '../../../assets/icons/NavLinkActive.svg'
import { ReactComponent as NewNavLinkInactive } from '../../../assets/icons/NavLinkInactive.svg'
import { ReactComponent as NewMenuIcon } from '../../../assets/icons/burger_menu_new.svg'
import { ReactComponent as NewChat } from '../../../assets/icons/chat_new.svg'
import { ReactComponent as NewFacebook } from '../../../assets/icons/facebook_new.svg'
import { ReactComponent as NewInstagram } from '../../../assets/icons/instagram_new.svg'
import { ReactComponent as NewLinkedin } from '../../../assets/icons/linkedin_new.svg'
import { ReactComponent as NewPhone } from '../../../assets/icons/phone_new.svg'
import { changePath } from '../../../features/breadcrumb/BreadcrumbSlice'

const navbarLinksProperty = [
	{ name: 'apartments', path: '/apartamente' },
	{ name: 'houses', path: '/case' },
	{ name: 'complexLocativ', path: '/complexe-locative' },
	{ name: 'commercialSpaces', path: '/spatii-comerciale' },
	{ name: 'lands', path: '/terenuri' },
	{ name: 'propertyAbroad', path: '/imobil-in-strainatate' },
]

const navbarLinksInfo = [
	{ name: 'aboutUs', path: '/despre' },
	{ name: 'echipa', path: '/echipa' },
	{ name: 'news', path: '/noutati' },
	// { name: "Blog", path: "/blog" },
	// { name: 'contacts', path: '/contact' },
]

const Navbar = ({ sticky }) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const [showMenu, setShowMenu] = useState(false)
	const [s, setSearch] = useState(false)
	const [menuOptionProperty, setMenuOptionsProperty] = useState(false)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [distanceToProperty, setDistanceToProperty] = useState(0)
	const [distanceToInfo, setDistanceToInfo] = useState(0)

	const [menuOptionInfo, setMenuOptionsInfo] = useState(false)
	const { t, i18n } = useTranslation()
	const logoConfig = {}

	if (sticky) {
		logoConfig.width = 40
		logoConfig.height = 40
	}

	const propertyRef = useRef(null)
	const infoRef = useRef(null)

	const handleLanguageChange = e => {
		i18n.changeLanguage(e.target.value)
	}

	useEffect(() => {
		const calculateDistances = () => {
			if (propertyRef.current && infoRef.current) {
				const propertyDivRect = propertyRef.current.getBoundingClientRect()
				const infoDivRect = infoRef.current.getBoundingClientRect()

				const distanceToProperty = propertyDivRect.left
				const distanceToInfo = infoDivRect.left

				setDistanceToProperty(distanceToProperty)
				setDistanceToInfo(distanceToInfo)
			}
		}

		calculateDistances()
		window.addEventListener('resize', calculateDistances)

		return () => {
			window.removeEventListener('resize', calculateDistances)
		}
	}, [])

	return (
		<nav className='position-relative'>
			<div className='container d-flex'>
				<div>
					<Link to='/'>
						<NewLogo />
					</Link>
				</div>
				<div className='ml-auto align-self-center'>
					<button
						className='d-lg-none dropdown-button border-0 bg-transparent'
						onClick={() => setShowMenu(true)}
					>
						<NewMenuIcon />
					</button>
					<div className={`d-flex menu ${showMenu ? 'active' : ''}`}>
						<button
							className='d-lg-none align-self-end border-0 bg-transparent mt-4 mr-3'
							style={{ transform: 'translatey(10px)' }}
							onClick={() => setShowMenu(false)}
						>
							<CloseMenuIcon />
						</button>
						{/* {navbarLinks.map((link, i) => (
							<div className='ml-4' key={i}>
								<Link
									type='link'
									role='link'
									className={`bg-transparent border-0 nav-link px-0 ${
										location.pathname === link.path ? 'active' : ''
									}`}
									onClick={() => {
										dispatch(changePath(link.name))
										if (showMenu) setShowMenu(false)
									}}
									to={link.path}
								>
									{t(link.name)}
								</Link>
							</div>
						))} */}
						<div className='ml-4' ref={propertyRef}>
							<Link
								type='link'
								role='link'
								className={`bg-transparent border-0 nav-link px-0`}
								onClick={() => {
									if (menuOptionProperty) {
										setMenuOptionsProperty(false)
									} else {
										setMenuOptionsProperty(true)
										setMenuOptionsInfo(false)
									}
									// dispatch(changePath('apartments'))
									// if (showMenu) setShowMenu(false)
								}}
								// to='/apartamente'
							>
								{t('property')}
								<NewNavLinkActive
									style={{
										marginLeft: '5px',
										marginTop: '-3px',
										display: `${menuOptionProperty ? '' : 'none'}`,
									}}
								/>
								<NewNavLinkInactive
									style={{
										marginLeft: '5px',
										marginTop: '-3px',
										display: `${menuOptionProperty ? 'none' : ''}`,
									}}
								/>
							</Link>
							<OptionsNavbar
								statusView={menuOptionProperty}
								optionsData={navbarLinksProperty}
								leftDistance={distanceToProperty}
							/>
						</div>
						<div className='ml-4' ref={infoRef}>
							<Link
								type='link'
								role='link'
								className={`bg-transparent border-0 nav-link px-0`}
								onClick={() => {
									if (menuOptionInfo) {
										setMenuOptionsInfo(false)
									} else {
										setMenuOptionsInfo(true)
										setMenuOptionsProperty(false)
									}
								}}
								// to='/apartamente'
							>
								{t('informations')}
								<NewNavLinkActive
									style={{
										marginLeft: '5px',
										marginTop: '-3px',
										display: `${menuOptionInfo ? '' : 'none'}`,
									}}
								/>
								<NewNavLinkInactive
									style={{
										marginLeft: '5px',
										marginTop: '-3px',
										display: `${menuOptionInfo ? 'none' : ''}`,
									}}
								/>
							</Link>
							<OptionsNavbar
								statusView={menuOptionInfo}
								optionsData={navbarLinksInfo}
								leftDistance={distanceToInfo}
							/>
						</div>
						<div className='ml-4'>
							<Link
								type='link'
								role='link'
								className={`bg-transparent border-0 nav-link px-0 ${
									location.pathname === '/favorite' ? 'active' : ''
								}`}
								onClick={() => {
									dispatch(changePath('favorite'))
									if (showMenu) setShowMenu(false)
								}}
								to='/favorite'
							>
								{t('favorite')}
							</Link>
						</div>
						<div className='ml-4'>
							<Link
								type='link'
								role='link'
								className={`bg-transparent border-0 nav-link px-0 ${
									location.pathname === '/apartments' ? 'active' : ''
								}`}
								onClick={() => {
									// dispatch(changePath('apartments'))
									// if (showMenu) setShowMenu(false)
								}}
								to='/'
							>
								{t('placeAd')}
							</Link>
						</div>

						<div className='social-section-nav'>
							<div className='mr-3 mb-2 mb-sm-0 social-item'>
								<a
									href='tel:+37368628686'
									className='text-white text-decoration-none social-item'
									target='_blank'
									rel='noopener noreferrer'
								>
									<NewPhone
										style={{
											marginRight: '8px',
										}}
									/>
									+373 68 628 686
								</a>
							</div>
							<div className='mr-3 mb-2 mb-sm-0 social-item'>
								<a
									href='https://www.instagram.com/topestate.md/'
									className='text-white text-decoration-none social-item'
									target='_blank'
									rel='noopener noreferrer'
								>
									<NewChat />
								</a>
							</div>

							<div className='mr-3 mb-2 mb-sm-0 social-item'>
								<a
									href='https://www.instagram.com/topestate.md/'
									className='text-white text-decoration-none social-item'
									target='_blank'
									rel='noopener noreferrer'
								>
									<NewInstagram />
								</a>
							</div>

							<div className='mr-3 mb-2 mb-sm-0 social-item'>
								<a
									href='https://www.facebook.com/topestate1'
									className='text-white text-decoration-none social-item'
									target='_blank'
									rel='noopener noreferrer'
								>
									<NewFacebook />
								</a>
							</div>

							<div className='mr-3 mb-2 mb-sm-0 social-item'>
								<a
									href='https://www.instagram.com/topestate.md/'
									className='text-white text-decoration-none social-item'
									target='_blank'
									rel='noopener noreferrer'
								>
									<NewLinkedin />
								</a>
							</div>
						</div>
						{/* Hidden Search */}
						{/* <div className=' align-self-start align-self-lg-center ml-3 search-wrap mb-3 mb-lg-0'>
							<input
								className='search-input search-container p-2'
								type='text'
								placeholder={t('search')}
								onChange={e => setSearch(e.target.value)}
							/>
							<Link
								className='border-0 bg-transparent ml-2 search-button text-decoration-none'
								to={{
									pathname: '/apartamente',
									search: `?s=${s}`,
								}}
							>
								<SearchIcon
									width={20}
									height={20}
									onClick={() => setShowMenu(false)}
								/>
							</Link>
						</div> */}
						<div className=' align-self-start align-self-lg-center ml-3 mb-3 mb-lg-0'>
							<select
								className='search-input p-2'
								type='text'
								value={
									['ro', 'ru', 'en'].includes(i18n.language)
										? i18n.language
										: 'ro'
								}
								onChange={handleLanguageChange}
							>
								<option value='ro'>RO</option>
								<option value='ru'>RU</option>
								<option value='en'>EN</option>
							</select>
						</div>
						{/* <div className='align-self-start align-self-lg-center ml-3 mb-3 mb-lg-0'>
							<Link className='heart-icon active' to='/favorite'>
								<HeartIcon width={20} height={20} />
							</Link>
						</div> */}
					</div>
				</div>
			</div>
		</nav>
	)
}

export default Navbar

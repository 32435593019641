import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { selectPaths } from '../../../features/breadcrumb/BreadcrumbSlice'

const OptionsNavbar = ({ statusView, optionsData, leftDistance }) => {
	const location = useLocation()
	const { t } = useTranslation()
	// const dispatch = useDispatch();
	const paths = useSelector(selectPaths)
	// useEffect(() => {
	//   dispatch(addPath("/apartamente"));
	// }, []);
	useEffect(() => {
		console.log(paths)
	}, [paths])

	console.log(leftDistance)
	return (
		<div
			className={`position-absolute option-submenu-items`}
			style={{
				left: leftDistance,
				display: `${statusView ? '' : 'none'}`,
			}}
		>
			{optionsData.map((link, i) => (
				<div className='ml-4' key={i}>
					<Link
						type='link'
						role='link'
						className={`bg-transparent option-item-link border-0 px-0 ${
							location.pathname === link.path ? 'active' : ''
						}`}
						onClick={() => {
							// dispatch(changePath(link.name))
							// if (showMenu) setShowMenu(false)
						}}
						to={link.path}
					>
						{t(link.name)}
					</Link>
				</div>
			))}
		</div>
	)
}

export default OptionsNavbar

import React from 'react'
import Navbar from './Navbar'

const Header = props => {
	return (
		<header>
			{/* <ContactTopBanner {...props} /> */}

			<Navbar />
			{/* 
			<div className='mt-3'>
				<Breadcrumb />
			</div> */}
		</header>
	)
}

export default Header
